<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
			<headerLayout></headerLayout>
		</section>
    <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>신청서/브로셔</h2>
                    <!-- <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p> -->
                </div>
                <div class="location">
                    <ul>
                        <li><a href="#">SUPPORT</a></li>
                        <li><a href="#">자료실</a></li>
                        <li><span>신청서/브로셔</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="sub-section dataAplcn">
            <div class="innWrap subTab01 flex">

                <div class="nav">
                    <ul>
                        <li><a v-bind:class="{ active: active }" @click="active = true">서류양식 다운로드</a></li>
                        <li><a v-bind:class="{ active: !active }" @click="active = false">영업점 방문 구비 서류</a></li>
                    </ul>
                </div>
                <div class="con">
                  <transition name="fade">
                    <div id="tab01" v-show="this.active">
                        <table class="tbl_type02">
                            <colgroup>
                                <col style="width: 200px;">
                                <col style="width: 440px;">
                                <col style="">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>항목</th>
                                    <th>상품명</th>
                                    <th>서류양식 다운로드</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowspan="8">기업인터넷</td>
                                    <td class="agl">Flexline</td>
                                    <td class="agl">
                                        <a href="https://enterprise.kt.com/cs/P_CS_BR_001.do" class="btn-downData" target="_blank">브로슈어</a>
                                        <a href="https://enterprise.kt.com/cs/P_CS_AF_001.do" class="btn-downData" target="_blank">신청서</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="agl">GiGA office</td>
                                    <td class="agl">
                                        <a href="https://enterprise.kt.com/cs/P_CS_BR_001.do" class="btn-downData" target="_blank">브로슈어</a>
                                        <a href="https://enterprise.kt.com/cs/P_CS_AF_001.do" class="btn-downData" target="_blank">신청서</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="agl">GiGA office(LAN2LAN)</td>
                                    <td class="agl">
                                        <a href="https://enterprise.kt.com/cs/P_CS_BR_001.do" class="btn-downData" target="_blank">브로슈어</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="agl">GiGA office(운용대행)</td>
                                    <td class="agl">
                                        <a href="https://enterprise.kt.com/cs/P_CS_BR_001.do" class="btn-downData" target="_blank">브로슈어</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="agl">GiGA office(mobile VPN)</td>
                                    <td class="agl">
                                        <a href="https://enterprise.kt.com/cs/P_CS_BR_001.do" class="btn-downData" target="_blank">브로슈어</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="agl">GiGA office(Gamehub)</td>
                                    <td class="agl">
                                        <a href="https://enterprise.kt.com/cs/P_CS_BR_001.do" class="btn-downData" target="_blank">브로슈어</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="agl">Kornet</td>
                                    <td class="agl">
                                        <a href="https://enterprise.kt.com/cs/P_CS_BR_001.do" class="btn-downData" target="_blank">브로슈어</a>
                                        <a href="https://enterprise.kt.com/cs/P_CS_AF_001.do" class="btn-downData" target="_blank">신청서</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="agl">VPN</td>
                                    <td class="agl">
                                        <a href="https://enterprise.kt.com/cs/P_CS_BR_001.do" class="btn-downData" target="_blank">브로슈어</a>
                                        <a href="https://enterprise.kt.com/cs/P_CS_AF_001.do" class="btn-downData" target="_blank">신청서</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="data-banner">
                            <a href="https://corp.kt.com/html/etc/agreement_02.html" target="_blank"><img src="@/assets/images/dataAplcn_banner01.png" alt="서비스 이용약관 바로가기" ></a>
                            <a href="https://get.adobe.com/kr/reader/" target="_blank"><img src="@/assets/images/dataAplcn_banner02.png" alt="뷰어프로그램 다운로드" ></a>
                        </div>
                    </div>
                  </transition>
                  <transition name="fade">
                    <div id="tab02" v-show="!this.active">
                        <h4>
                            고객유형, 신규/변경, 명의변경, 해지 사항을<br />
                            개인사업자, 법인, 국가기관/지자체 별로 설명한 서비스 신청/변경/해지 요청 시 구비서류
                        </h4>
                        <table class="tbl_type02">
                            <colgroup>
                                <col style="">
                                <col style="width: 340px;">
                                <col style="width: 340px;">
                                <col style="width: 340px;">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>고객유형</th>
                                    <th>신규/변경</th>
                                    <th>명의변경</th>
                                    <th>해지</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>개인사업자</td>
                                    <td class="agl">
                                        사업자등록증, 대표자신분증<br />
                                        위임시 : 대표자인감증명, 위임장, 대리인신분증
                                    </td>
                                    <td class="agl">
                                        사업자등록증, 대표자신분증<br />
                                        위임시 : 대표자인감증명, 위임장, 대리인신분증<br />
                                        양도인/양수인 모두 필요
                                    </td>
                                    <td class="agl">
                                        대표자신분증<br />
                                        위임시 : 대표자인감증명, 위임장, 대리인신분증
                                    </td>
                                </tr>
                                <tr>
                                    <td>법인</td>
                                    <td class="agl">
                                        사업자등록증, 대표자신분증<br />
                                        위임시 : 법인인감증명, 사업자등록증, 위임장, <br />
                                        대리인신분증
                                    </td>
                                    <td class="agl">
                                        사업자등록증, 대표자신분증<br />
                                        위임시 : 대표자인감증명, 위임장, 대리인신분증<br />
                                        양도인/양수인 모두 필요
                                    </td>
                                    <td class="agl">
                                        사업자등록증, 대표자신분증<br />
                                        위임시 : 대표자인감증명, 위임장, 대리인신분증<br />
                                    </td>
                                </tr>
                                <tr>
                                    <td>국가기관/지자체</td>
                                    <td class="agl">
                                        고유번호증, 위임장(공문), <br />
                                        대리인신분증(공무원증)
                                    </td>
                                    <td class="agl">
                                        고유번호증, 위임장(공문), <br />
                                        대리인신분증(공무원증)<br />
                                        양도인/양수인 모두 필요
                                    </td>
                                    <td class="agl">
                                        고유번호증, 위임장(공문), <br />
                                        대리인신분증(공무원증)
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="dotList mgt20">
                            <ul>
                                <li>일반적인 구비서류이므로 상황에 따라 대체서류, 필요한 구비서류가 다를 수 있습니다.</li>
                                <li>자세한 상담 및 추가안내가 필요하신 경우 기업고객센터 1588-0114로 연락 부탁드립니다.</li>
                            </ul>
                        </div>
                    </div>
                  </transition>
                </div>
            </div>
        </section>
    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {
      active: true
    }
  },
  created: function () {

  },
  mounted: function () {
  },
  methods: {
  }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
